
import {defineComponent, onMounted, ref} from 'vue'
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import Chart from "@/views/chart/Chart.vue";
import {ChartCode, ChartType} from "@/core/config/Constant";

export default defineComponent({
  name: "TeamSystemTime",
  components: {  Chart},
  setup() {
    // const clientId = ref('');
    const mounted = ref(false)
    onMounted(() => {
      mounted.value = true;
      setCurrentPageBreadcrumbsWithParams("System Log Dashboard", [])
    })

    return{
      mounted,
      // clientId,
      ChartCode,
      ChartType,
    }
  }
})
